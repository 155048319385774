import { Injectable } from '@angular/core'
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http'
import { AuthService } from '../auth/services/auth.service'
import { Observable } from 'rxjs'

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUser = this.authService.currentUserValue
    const userid = this.authService.CurrentUserObj
    if (currentUser) {
      request = request.clone({
        setHeaders: {
          Authorization: currentUser,
          userid
        }
      })
    }
    return next.handle(request)
  }
}
