import { HttpErrorResponse } from '@angular/common/http'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html'
})
export class DashboardComponent implements OnInit {
  report: any

  ageGraphData: any[] = []
  genderGraphData: any[] = []

  cardWidth: number = 300
  colorScheme = {
    domain: ['#673ab7', '#007bff', '#6610f2', '#6f42c1', '#e83e8c', '#dc3545', '#fd7e14', '#ffc107', '#28a745', '#20c997', '#17a2b8']
  }

  constructor(private allService: AllService, private toasterService: ToastrService, private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.allService.getDashboardReport().subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.report = res.payload
        this.ageGraphData = this.report.userStatics.ageData
        this.genderGraphData = this.report.userStatics.genderData
        this.cdr.detectChanges()
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
}
