import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'
import { PageChangedEvent } from 'ngx-bootstrap/pagination'
import { Router } from '@angular/router'

@Component({
  selector: 'app-uplift',
  templateUrl: './uplift.component.html',
  styleUrls: ['./uplift.component.scss']
})
export class UpliftComponent implements OnInit {
  totalCount: number = 0
  limit: number = 10
  currentPage: number = 1

  uplifts: any = []
  userList: any = []
  modalRef: BsModalRef
  affId: string = ''
  submitted: boolean = false
  loading: boolean = true
  previewUrl: string = ''
  editUpliftId: string = ''
  editUplift: any
  searchKey: string = ''
  linkPreview: any
  upliftForm: FormGroup = new FormGroup({
    userId: new FormControl(''),
    tag: new FormControl('', [Validators.required]),
    post: new FormControl('', [Validators.required, Validators.maxLength(1500)]),
    serialNo: new FormControl('', [Validators.required])
  })
  deleteUpliftId: string
  constructor(private toasterService: ToastrService, private allService: AllService, private modalService: BsModalService, private route: Router) {}
  get f() {
    return this.upliftForm.controls
  }
  ngOnInit(): void {
    this.getUpliftsList(this.currentPage, this.limit)
  }
  openAddUpliftModel(popUp: TemplateRef<any>): void {
    this.searchKey = ''
    this.previewUrl = ''
    this.linkPreview = ''
    this.userList = []
    this.submitted = false
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
  }
  createUplift(): void {
    if (this.upliftForm.invalid) {
      this.submitted = true
      return
    }
    let data = this.upliftForm.value
    this.allService.createUplift(data).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success('Uplift added')
        this.uplifts.splice(0, 0, res.payload)
        this.upliftForm.patchValue({ userId: '', tag: '', post: '', serialNo: '' })
        this.modalRef.hide()
        this.totalCount++
        if (this.uplifts.length > this.limit) {
          this.uplifts.pop()
        }
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  openDeleteConfirmationModel(popUp: TemplateRef<any>, upliftId: string): void {
    this.deleteUpliftId = upliftId
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
  }
  deleteUplift(): void {
    this.allService.deleteUplift(this.deleteUpliftId).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success('Uplift deleted')
        this.uplifts = this.uplifts.filter((uplift: any) => uplift._id !== this.deleteUpliftId)
        this.totalCount--
        this.deleteUpliftId = ''
        this.modalRef.hide()
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  openEditUpliftModel(popUp: TemplateRef<any>, uplift: any): void {
    this.selectUser(uplift.user)
    this.submitted = false
    this.editUpliftId = uplift._id
    this.editUplift = uplift
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
    this.upliftForm.patchValue({ ...uplift })
    this.extractUrl()
    this.getPreview()
  }
  updateUplift(): void {
    if (this.upliftForm.invalid) {
      this.submitted = true
      return
    }
    let updatedData = this.findUpdatedData(this.editUplift, { ...this.upliftForm.value })

    this.allService.updateUplift({ ...updatedData, upliftId: this.editUpliftId }).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success('Uplift updated')
        this.uplifts = this.uplifts.map((uplift: any) => (uplift._id === res.payload._id ? res.payload : uplift))
        this.upliftForm.patchValue({ userId: '', tag: '', post: '', serialNo: '' })
        this.modalRef.hide()
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  onSearch(): void {
    const searchKey = this.searchKey.trim()
    if (!searchKey) this.userList = []
    else {
      this.allService.getSearchedUser(searchKey).subscribe(
        (res: any) => {
          if (res.errorStatus) {
            this.toasterService.error(res.error)
            return
          }
          this.userList = res.payload
        },
        (err: HttpErrorResponse) => {
          this.toasterService.error(err.error?.error || err.message)
        }
      )
    }
  }
  selectUser(user: any): void {
    this.upliftForm.patchValue({ userId: user._id })
    this.userList = []
    this.searchKey = `${user.firstName} ${user.lastName}`
  }
  getUpliftsList(page: number, limit: number): void {
    this.allService.getUplift(page, limit).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.uplifts = res.payload.uplifts
        this.totalCount = res.payload.totalCount
        this.loading = false
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
        this.loading = false
      }
    )
  }
  pageChanged(event: PageChangedEvent): void {
    this.getUpliftsList(event.page, event.itemsPerPage)
  }
  findUpdatedData(obj1: object, obj2: object) {
    let updatedObj = {}
    if (obj1['user']['_id'] !== obj2['userId']) {
      updatedObj['userId'] = obj2['userId']
    } else if (obj1['tag'] !== obj2['tag']) {
      updatedObj['tag'] = obj2['tag']
    } else if (obj1['post'] !== obj2['post']) {
      updatedObj['post'] = obj2['post']
    } else if (obj1['serialNo'] !== obj2['serialNo']) {
      updatedObj['serialNo'] = obj2['serialNo']
    }
    return updatedObj
  }
  extractUrl(): void {
    let post = this.upliftForm.get('post').value
    const urls = post?.replace(/(https?:\/\/[^\s/$.?#].[^\s]*)/g, ', $1 ,').match(/(https?:\/\/[^\s]+)/g) || []
    if (post && urls.length) {
      this.previewUrl = urls.pop()
    } else {
      this.previewUrl = ''
      this.linkPreview = ''
    }
  }
  getPreview(): void {
    if (!this.previewUrl) return
    this.allService.getPreviewDetails(this.previewUrl).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.linkPreview = res.payload.metadata
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  cancelBtn(): void {
    this.upliftForm.patchValue({
      userId: '',
      tag: '',
      post: '',
      serialNo: ''
    })
    this.modalRef.hide()
    this.previewUrl = ''
    this.linkPreview = ''
    this.searchKey = ''
    this.userList = []
    this.submitted = false
    this.editUpliftId = ''
    this.editUplift = ''
  }
  redirectToUpliftDetail(upliftId: string): void {
    this.route.navigate(['uplift-detail'], { queryParams: { upliftId } })
  }
}
