import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-journal-questions',
  templateUrl: './journal-questions.component.html'
})
export class JournalQuestionsComponent implements OnInit {
  journalQuestionList: [JournalQuestion]
  modalRef: BsModalRef
  submitted: boolean = false
  showMultiple: boolean = false
  questionForm: FormGroup

  constructor(private fb: FormBuilder, private allService: AllService, private toasterService: ToastrService, private modalService: BsModalService) {}

  createForm() {
    this.questionForm = new FormGroup({
      _id: new FormControl(''),
      question: new FormControl('', [Validators.required]),
      questionType: new FormControl('', [Validators.required]),
      answers: this.fb.array([])
    })
  }
  get answersArr(): FormArray {
    return this.questionForm.get('answers') as FormArray
  }

  addControlToAnswers(ans: string): void {
    const newControl = this.fb.control(ans, Validators.required)
    ;(this.questionForm.get('answers') as FormArray).push(newControl)
  }

  ngOnInit(): void {
    this.allService.getAllJournalQuestion().subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.journalQuestionList = res.payload
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  get f() {
    return this.questionForm.controls
  }
  openQuestionModal(popUp: TemplateRef<any>, questionId: string): void {
    this.createForm()
    this.questionForm.reset()
    this.submitted = false
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
    let journal = this.journalQuestionList.find((ele) => ele._id === questionId)
    this.questionForm.patchValue(journal)
    if (journal?.answers?.length) {
      for (const ans of journal?.answers) {
        this.addControlToAnswers(ans)
      }
    }
  }
  updateQuestion(): void {
    this.submitted = true
    if (this.questionForm.invalid) return

    this.allService.updateJournalQuestion(this.questionForm.value).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success(res.message)
        for (const ele of this.journalQuestionList) {
          if (ele._id == res?.payload?._id) {
            ele['question'] = res?.payload?.question
            break
          }
        }
        this.modalRef.hide()
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
        this.modalRef.hide()
      }
    )
  }
}

interface JournalQuestion {
  _id: string
  question: string
  questionType: string
  answers?: [string]
  createdAt?: string
  updatedAt?: string
}
