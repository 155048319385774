<div class="content">
  <div class="d-flex justify-content-between mb-2">
    <button class="btn btn-primary" (click)="goBack()"><img src="../../../assets/maunda/back.svg" alt="back" /> Back</button>
  </div>
  <!-- {{ report | json }} -->
  <div class="card p-5">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header">
            <p class="m-0">Blocked Uplift Details</p>
          </div>
          <div class="card-body row">
            <img [src]="report.postedBy.profilePicUrl" alt="profilePicUrl" style="width: 300px; height: 250px; border-radius: 5px; border: 1px solid black; border-radius: 5px" class="col-md-6 col-sm-6 ml-3" />
            <div class="col-md-6">
              <p><span>Username: </span> {{ report.postedBy.username }}</p>
              <p><span>Email: </span> {{ report.postedBy.email }}</p>
              <p><span>Name: </span> {{ report.postedBy.firstName }} {{ report.postedBy.lastName }}</p>
              <p><span>Gender: </span> {{ getGender(report.postedBy.gender) }}</p>
              <p><span>Date of birth: </span> {{ report.postedBy.dob | date : 'dd MMM, YYYY' || '' }}</p>
              <p><span>Phone: </span> {{ report.postedBy.mobileCountryCode }} {{ report.postedBy.phoneNumber }}</p>
              <p>
                <span>Status: </span>
                <span class="block-status" [ngClass]="report.postedBy.isBlocked ? ' bg-danger' : ' bg-success'">{{ report.postedBy.isBlocked ? 'Blocked' : 'Active' }}</span>
              </p>
              <p><span>Streak Count: </span> {{ report.postedBy.streakCount }}</p>
              <p><span>Meditation Count: </span> {{ report.postedBy.meditationCount }}</p>
            </div>

            <div class="card border-0 mt-2">
              <p>
                <span>Uplift status: </span>
                <span class="block-status" [ngClass]="report.isBlocked ? ' bg-danger' : ' bg-success'">{{ report.isBlocked ? 'Blocked' : 'Active' }}</span>
              </p>
              <p><span>Comment count: </span> {{ report.commentCount }}</p>
              <p><span>Like count: </span> {{ report.likeCount }}</p>
              <p><span>Description: </span></p>
              <div class="d-flex" style="height: 300px">
                <div class="card-body" style="overflow-y: auto; scrollbar-width: thin">
                  <div *ngIf="report?.post; else tempText" [innerHTML]="report.post"></div>
                  <ng-template #tempText>
                    <div>No Description Added by User</div>
                  </ng-template>
                </div>
              </div>
              <br />
              <ng-container *ngIf="previewUrl && linkPreview">
                <a [href]="previewUrl" class="border d-inline-flex p-3 rounded" style="text-decoration: none; color: black" target="_blank">
                  <img [src]="linkPreview.image" alt="" style="max-width: 50px; max-height: 50px; margin: auto" />
                  <div>
                    <p class="ms-2 m-0" style="font-size: 14px; padding: 10px 10px 0px 10px; line-height: normal">{{ linkPreview.title }}</p>
                    <p class="ms-2 m-0" style="font-size: 12px; padding: 0px 10px; margin: 0px; line-height: normal">{{ linkPreview.description }}</p>
                  </div>
                </a>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card" style="height: 500px">
          <div class="card-header">
            <p class="m-0">Reported By ({{ report?.blockedBy?.length }})</p>
          </div>
          <div class="card-body" style="overflow-y: auto; scrollbar-width: thin">
            <div class="form-control d-flex mb-2" *ngFor="let item of report?.blockedBy">
              <img [src]="item.profilePicUrl" alt="profilePicUrl" style="width: 50px; height: 50px; border-radius: 5px" />
              <div>
                <p class="m-0 ms-2" style="font-size: 14px; font-weight: 500">{{ item.username }}</p>
                <p class="m-0 ms-2" style="font-size: 11px">{{ item.firstName }} {{ item.lastName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
