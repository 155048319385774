import { Component, OnInit } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-report-uplift-detail',
  templateUrl: './report-uplift-detail.component.html',
  styleUrls: ['./report-uplift-detail.component.scss']
})
export class ReportUpliftDetailComponent implements OnInit {
  upliftId: string
  report: any
  previewUrl: string = ''
  linkPreview: any
  constructor(private toasterService: ToastrService, private allService: AllService, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe((params) => {
      this.upliftId = params['upliftId']
    })
  }

  ngOnInit(): void {
    console.log(this.upliftId)
    this.allService.getBlockedUpliftWithUserList(this.upliftId).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.report = res.payload?.[0] || null
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  goBack(): void {
    window.history.back()
  }
  getGender(genderCode: string): string {
    if (genderCode === 'M') return 'Male'
    else if (genderCode === 'F') return 'Female'
    else if (genderCode === 'O') return 'Other'
    else if (genderCode === 'PN') return 'Prefer not to specify'
    else return 'Unknown'
  }

  extractUrl(post: string): void {
    const urls = post?.replace(/(https?:\/\/[^\s/$.?#].[^\s]*)/g, ', $1 ,').match(/(https?:\/\/[^\s]+)/g) || []
    if (urls.length) {
      this.previewUrl = urls.pop()
      this.allService.getPreviewDetails(this.previewUrl).subscribe((res: any) => {
        if (res.errorStatus) {
          return
        }
        this.linkPreview = res.payload.metadata
      })
    } else {
      this.previewUrl = ''
      this.linkPreview = ''
    }
  }
  formatPostText(post: string): string {
    return post.replace(/(https?:\/\/[^\s]+)/g, '<br><a href="$1" target="_blank">$1</a>')
  }
}
