<div class="content">
  <div class="d-flex justify-content-center">
    <div style="width: 700px">
      <div class="card mb-2" *ngFor="let item of journalQuestionList; let i = index">
        <img src="../../../assets/maunda/edit.svg" (click)="openQuestionModal(editQuestion, item._id)" alt="" style="width: 18px; height: 18px; position: absolute; top: 0; right: 0; margin: 15px" />
        <div class="card-body">
          <h3 class="card-title m-0 pr-4">{{ i + 1 }}Q: {{ item.question }}</h3>
          <h5 class="card-subtitle mb-2 text-muted text-capitalize">({{ item.questionType }})</h5>
          <ul class="m-0" *ngIf="item.questionType != 'text'">
            <li style="list-style: none" class="text-capitalize" *ngFor="let ans of item.answers"><span class="dot"> </span>{{ ans }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #editQuestion>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Update Journal Question</h2>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="questionForm">
      <div class="form-group">
        <label class="form-label" for="question">Question:</label>
        <textarea id="question" class="form-control" cols="30" rows="2" formControlName="question"></textarea>
        <div *ngIf="submitted && f.question.errors" class="alert alert-danger">
          <span *ngIf="f.question.errors.required">question is required</span>
        </div>
      </div>
      <div class="form-group">
        <label class="form-label" for="questionType">Question Type:</label>
        <input type="text" class="form-control text-capitalize" readonly [disabled]="true" formControlName="questionType" />
      </div>
      <div *ngIf="f.questionType.value != 'text'" class="form-group">
        <label class="form-label" for="answers">Answers:</label>
        <div formArrayName="answers">
          <div *ngFor="let control of answersArr.controls; let i = index" class="align-items-center border-0 d-flex form-control pb-0 pt-0">
            <span class="dot"> </span>
            <input type="text" class="border-0 border-bottom w-100" disabled [formControl]="control" />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-lightgray" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="updateQuestion()">Update</button>
  </div>
</ng-template>
