<div class="content">
  <div class="row" style="margin: 0 auto">
    <!-- Today's New Users -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/user.png" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Today's New Users</h2>
            </div>
            <span style="font-size: 25px">{{ report?.userStatics?.newRegisteredUser }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Total Users -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/users.png" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Total Users</h2>
            </div>
            <span style="font-size: 25px">{{ report?.userStatics?.totalRegisteredUser }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Total Meditation Count -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/meditation-black.svg" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Total Meditation Count</h2>
            </div>
            <span style="font-size: 25px">{{ report?.userStatics?.totalMeditationCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Total Uplift -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/uplift-white.svg" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Total Uplift</h2>
            </div>
            <span style="font-size: 25px">{{ report?.totalUpliftCount }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Today's Journal Submitted -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/journal-black.svg" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Today's Journal Submitted</h2>
            </div>
            <span style="font-size: 25px">{{ report?.userStatics?.todayJournal }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Total Journal Submitted -->
    <div class="col-4 p-1">
      <div class="card shadow" style="min-height: 247px; border: 1px solid #673ab7">
        <div class="align-items-center card-body d-flex justify-content-center text-center">
          <div class="card-body text-center">
            <div class="d-flex justify-content-center">
              <img src="../../../assets/maunda/journal-black.svg" alt="" style="height: 26px" />&nbsp;&nbsp;
              <h2 style="font-size: 20px; font-weight: 700">Total Journal Submitted</h2>
            </div>
            <span style="font-size: 25px">{{ report?.userStatics?.totalJournal }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row p-1" style="margin: 0 auto">
    <div *ngIf="ageGraphData.length" class="col-md-6 p-0">
      <div #myDiv1 class="card shadow">
        <div class="card-header" style="background-color: #673ab7; color: white">
          <h1 class="card-title">Age Group</h1>
        </div>
        <div class="card-body">
          <ngx-charts-bar-vertical
            [view]="[myDiv1.offsetWidth - 30, 400]"
            [scheme]="colorScheme"
            [results]="ageGraphData"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Age Range'"
            [yAxisLabel]="'Total User Count'"
            [showDataLabel]="true"
          >
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
    <div *ngIf="genderGraphData.length" class="col-md-6 pl-1 pr-0">
      <div #myDiv2 class="card shadow">
        <div class="card-header" style="background-color: #673ab7; color: white">
          <h1 class="card-title">Gender Group</h1>
        </div>
        <div class="card-body">
          <ngx-charts-bar-vertical
            [view]="[myDiv2.offsetWidth - 30, 400]"
            [scheme]="colorScheme"
            [results]="genderGraphData"
            [gradient]="false"
            [xAxis]="true"
            [yAxis]="true"
            [showXAxisLabel]="true"
            [showYAxisLabel]="true"
            [xAxisLabel]="'Gender'"
            [yAxisLabel]="'Total User Count'"
            [showDataLabel]="true"
          >
          </ngx-charts-bar-vertical>
        </div>
      </div>
    </div>
  </div>
</div>
