<div class="content">
  <div class="d-flex justify-content-between mb-2">
    <button class="btn btn-primary" (click)="goBack()"><img src="../../../assets/maunda/back.svg" alt="back" /> Back</button>
  </div>
  <div class="card p-5">
    <div class="row">
      <div class="col-md-7">
        <div class="card">
          <div class="card-header">
            <p class="m-0">Reported On</p>
          </div>
          <div class="card-body row">
            <img [src]="report.profilePicUrl" alt="profilePicUrl" style="width: 300px; height: 250px; border-radius: 5px; border: 1px solid black; border-radius: 5px" class="col-md-6 col-sm-6 ml-3" />
            <div class="col-md-6">
              <p><span>Username: </span> {{ report.username }}</p>
              <p><span>Email: </span> {{ report.email }}</p>
              <p><span>Name: </span> {{ report.firstName }} {{ report.lastName }}</p>
              <p><span>Gender: </span> {{ getGender(report.gender) }}</p>
              <p><span>Date of birth: </span> {{ report.dob | date : 'dd MMM, YYYY' || '' }}</p>
              <p><span>Phone: </span> {{ report.mobileCountryCode }} {{ report.phoneNumber }}</p>
              <p>
                <span>Blocked status: </span>
                <span class="block-status" [ngClass]="report.isBlocked ? ' bg-danger' : ' bg-success'">{{ report.isBlocked ? 'Blocked' : 'Active' }}</span>
              </p>
              <p><span>Streak Count: </span> {{ report.streakCount }}</p>
              <p><span>Meditation Count: </span> {{ report.meditationCount }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="card" style="height: 500px">
          <div class="card-header">
            <p class="m-0">Reported By ({{ report?.blockedByUserData?.length }})</p>
          </div>
          <div class="card-body" style="overflow-y: auto; scrollbar-width: thin">
            <div class="form-control d-flex mb-2" *ngFor="let item of report?.blockedByUserData">
              <img [src]="item.profilePicUrl" alt="profilePicUrl" style="width: 50px; height: 50px; border-radius: 5px" />
              <div>
                <p class="m-0 ms-2" style="font-size: 14px; font-weight: 500">{{ item.username }}</p>
                <p class="m-0 ms-2" style="font-size: 11px">{{ item.firstName }} {{ item.lastName }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
