import { Component } from '@angular/core'
@Component({
  selector: 'app-layout',
  template: `
    <app-sidebar></app-sidebar>
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  `
})
export class LayoutComponent {}
