<div class="content">
  <div class="table-responsive bg-white">
    <table class="table border table-hover m-0 rounded" aria-describedby="default-affirmation">
      <thead>
        <tr>
          <th scope="col">S.No</th>
          <th scope="col">Quotes</th>
          <th scope="col" class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody *ngIf="defaultAffirmationList?.length">
        <tr *ngFor="let aff of defaultAffirmationList; let i = index">
          <td>{{ i + 1 }}</td>
          <td>{{ aff.quote }}</td>
          <td class="text-center">
            <span style="cursor: pointer; margin-right: 10px" (click)="openEditModal(editAffirmation, aff._id, aff.quote)">
              <img src="../../../assets/maunda/edit.svg" alt="edit" />
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #editAffirmation>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Update Affirmation</h2>
    </div>
  </div>
  <div class="modal-body">
    <form [formGroup]="affirmationForm">
      <div class="form-group">
        <label class="form-label" for="quote">Quote:<span style="color: red">*</span></label>
        <textarea id="quote" class="form-control" cols="30" rows="5" formControlName="quote"></textarea>
        <div *ngIf="submitted && f.quote.errors" class="alert alert-danger">
          <span *ngIf="f.quote.errors.required">Quote is required</span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-lightgray" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="updateAffirmation()">Update</button>
  </div>
</ng-template>
