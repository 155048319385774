<div class="content">
  <div class="password-change">
    <div class="box">
      <div class="d-flex justify-content-center">
        <h2>Change Password</h2>
      </div>
      <form [formGroup]="passwordChangeForm" (submit)="openConfirmModel(template)">
        <div class="form-group">
          <label for="password">Old Password: <span>*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="password" [type]="visible.password ? 'text' : 'password'" class="form-control" formControlName="password" autocomplete="off" placeholder="Enter old password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.password" (click)="visible.password = !visible.password" src="../../../assets/maunda/eye-hide.svg" class="m-2" alt="" />
              <img *ngIf="visible.password" (click)="visible.password = !visible.password" src="../../../assets/maunda/eye-show.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
            <span *ngIf="f.password.errors.required"> Old password is required </span>
          </div>
        </div>
        <div class="form-group">
          <label for="newPassword">New Password: <span>*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="newPassword" [type]="visible.newPassword ? 'text' : 'password'" class="form-control" formControlName="newPassword" autocomplete="new-password" placeholder="Enter new password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.newPassword" (click)="visible.newPassword = !visible.newPassword" src="../../../assets/maunda/eye-hide.svg" class="m-2" alt="" />
              <img *ngIf="visible.newPassword" (click)="visible.newPassword = !visible.newPassword" src="../../../assets/maunda/eye-show.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.newPassword.errors" class="alert alert-danger">
            <h5 class="p-0 m-0">Please choose a stronger password</h5>
            <ul>
              <li>It should contain at least 6 characters</li>
              <li>Maximum 10 characters</li>
              <li>1 Lowercase letter</li>
              <li>1 Uppercase letter</li>
              <li>1 Special character and 1 Number</li>
            </ul>
          </div>
        </div>
        <div class="form-group">
          <label for="confirmPassword">Retype Password: <span>*</span></label>
          <div class="align-items-center d-flex flex-row-reverse">
            <input id="confirmPassword" [type]="visible.confirmPassword ? 'text' : 'password'" class="form-control" autocomplete="new-password" formControlName="confirmPassword" placeholder="Retype new password" />
            <span class="input-icon-suffix">
              <img *ngIf="!visible.confirmPassword" (click)="visible.confirmPassword = !visible.confirmPassword" src="../../../assets/maunda/eye-hide.svg" class="m-2" alt="" />
              <img *ngIf="visible.confirmPassword" (click)="visible.confirmPassword = !visible.confirmPassword" src="../../../assets/maunda/eye-show.svg" class="m-2" alt="" />
            </span>
          </div>
          <div *ngIf="submitted && f.confirmPassword.errors" class="alert alert-danger">
            <h5 class="p-0 m-0">Both password must match.</h5>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <button type="submit" class="btn btn-primary">Update</button>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #template>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h2 class="m-0 p-0">Change Password</h2>
    </div>
  </div>
  <div class="modal-body text-center delete-modal align-items-center">
    <img src="../../../assets/maunda/create-successfull-img.svg" alt="" />
    <p>
      Are you sure you want<br />
      to change your password?
    </p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-lightgray" (click)="modalRef.hide()">No</button>
    <button class="btn btn-green ms-2" (click)="confirmUpdatePassword()">Yes</button>
  </div>
</ng-template>
