import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-reported-user-detail',
  templateUrl: './reported-user-detail.component.html',
  styleUrls: ['./reported-user-detail.component.scss']
})
export class ReportedUserDetailComponent implements OnInit {
  userId: string
  report: any
  constructor(private toasterService: ToastrService, private allService: AllService, private activeRoute: ActivatedRoute) {
    this.activeRoute.queryParams.subscribe((params) => {
      this.userId = params['userId']
    })
  }

  ngOnInit(): void {
    console.log(this.userId)
    this.allService.getBlockedUserWithUserList(this.userId).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.report = res.payload?.[0] || null
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  goBack(): void {
    window.history.back()
  }
  getGender(genderCode: string): string {
    if (genderCode === 'M') return 'Male'
    else if (genderCode === 'F') return 'Female'
    else if (genderCode === 'O') return 'Other'
    else if (genderCode === 'PN') return 'Prefer not to specify'
    else return 'Unknown'
  }
}
