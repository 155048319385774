<div class="content">
  <div class="table-responsive bg-white mb-2">
    <table class="table border table-hover rounded" aria-describedby="default-uplift">
      <thead>
        <tr>
          <th>S.No</th>
          <th>Reported Uplift</th>
          <th class="text-center">Report Count</th>
          <th>Last Reported On</th>
          <th class="text-center">Status</th>
          <th class="text-center">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let report of blockedReports; let i = index">
          <td>{{ (currentPage - 1) * limit + (i + 1) }}</td>
          <td style="max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis">{{ report?.upliftPost || '' }}</td>
          <td class="text-center">{{ report?.reportCount || 0 }}</td>
          <td>{{ report?.createdAt | date : 'dd MMM YYYY, HH:mm a' || '' }}</td>
          <td class="text-center">
            <span style="padding: 4px 8px; border-radius: 20px" [ngClass]="report.isResolved ? 'bg-success text-white' : report?.reportRejectStatus ? 'bg-danger text-white' : ' bg-warning'">{{
              report?.isResolved ? 'Approved' : report?.reportRejectStatus ? 'Request Rejected' : 'Pending'
            }}</span>
          </td>
          <td class="text-center">
            <button class="btn btn-sm btn-primary" (click)="goToReportUpliftDetails(report?._id)">View</button>
            <button [disabled]="report?.upliftBlocked" (click)="openConfirmationModel(confirmModelForBlock, report, 'approve')" class="btn btn-sm btn-success ms-1">Approve</button>
            <button [disabled]="report?.upliftBlocked || report?.reportRejectStatus" (click)="openConfirmationModel(confirmModelForBlock, report, 'reject')" class="btn btn-sm btn-danger ms-1">Reject</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="d-flex justify-content-center" *ngIf="!blockedReports.length && !loading">
    <span style="font-size: 25px; padding: 2rem">No Uplift Reported Yet!</span>
  </div>

  <pagination
    *ngIf="blockedReports.length && totalCount > limit"
    [boundaryLinks]="true"
    [totalItems]="totalCount"
    [itemsPerPage]="limit"
    [(ngModel)]="currentPage"
    (pageChanged)="pageChanged($event)"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    [maxSize]="limit"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>

<ng-template #confirmModelForBlock>
  <div class="modal-header">
    <div class="d-flex justify-content-center w-100">
      <h1 class="m-0 p-0" style="font-weight: 600">{{ selectedReport?.status == 'approve' ? 'Block' : 'Reject' }} Uplift</h1>
    </div>
  </div>
  <div class="modal-body text-center delete-modal">
    <img src="../../../assets/maunda/delete-img.svg" alt="" />
    <p>Are you sure you want to {{ selectedReport?.status == 'approve' ? 'block' : 'reject' }} this uplift?</p>
  </div>
  <div class="modal-footer modal-btn">
    <button class="btn btn-cancel" (click)="modalRef.hide()">Cancel</button>
    <button class="btn btn-green ms-2" (click)="updateUpliftStatus()">Confirm</button>
  </div>
</ng-template>
