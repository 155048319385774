import { HttpErrorResponse } from '@angular/common/http'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { ToastrService } from 'ngx-toastr'
import { AllService } from 'src/app/services/all.service'

@Component({
  selector: 'app-affirmation',
  templateUrl: './affirmation.component.html'
})
export class AffirmationComponent implements OnInit {
  defaultAffirmationList: Array<DefaultAffirmation> = []
  modalRef: BsModalRef
  affId: string = ''
  submitted: boolean = false
  affirmationForm = new FormGroup({
    affId: new FormControl('', [Validators.required]),
    quote: new FormControl('', [Validators.required])
  })

  constructor(private allService: AllService, private toasterService: ToastrService, private modalService: BsModalService) {}
  get f() {
    return this.affirmationForm.controls
  }
  ngOnInit(): void {
    this.allService.getDefaultAffirmation().subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.defaultAffirmationList = res.payload
      },
      (err) => {
        this.toasterService.error(err.error?.error || err.message)
      }
    )
  }
  openEditModal(popUp: TemplateRef<any>, affId: string, quote: string): void {
    this.modalRef = this.modalService.show(popUp, { ignoreBackdropClick: true, backdrop: 'static', keyboard: true })
    this.affirmationForm.patchValue({ affId, quote })
  }
  updateAffirmation(): void {
    if (this.affirmationForm.invalid) {
      this.toasterService.error('Quote required')
      return
    }
    this.allService.updateAffirmation(this.affirmationForm.value).subscribe(
      (res: any) => {
        if (res.errorStatus) {
          this.toasterService.error(res.error)
          return
        }
        this.toasterService.success(res.message)
        let affId = this.affirmationForm.get('affId').value
        for (const aff of this.defaultAffirmationList) {
          if (aff._id === affId) {
            aff.quote = this.affirmationForm.get('quote').value
            break
          }
        }
        this.modalRef.hide()
      },
      (err: HttpErrorResponse) => {
        this.toasterService.error(err.error?.error || err.message)
        this.modalRef.hide()
      }
    )
  }
}

interface DefaultAffirmation {
  _id: string
  quote: string
  createdAt?: string
  updatedAt?: string
}
