import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from '../../../environments/environment'
import { User } from '../models/user.model'
import { Subject } from 'rxjs'

@Injectable()
export class AuthService {
  private emailSend = new Subject<any>()
  child1$ = this.emailSend.asObservable()

  SendToChild1(msg: string) {
    this.emailSend.next(msg)
  }

  url: any = environment.API_URL

  constructor(private http: HttpClient) {}

  public get currentUserValue() {
    let data = localStorage.getItem('currentUser')
    if (data !== 'undefined') return JSON.parse(localStorage.getItem('currentUser'))
    return false
  }
  setCurrentUserObj(user: any) {
    localStorage.setItem('userid', user._id)
  }
  public get CurrentUserObj() {
    return localStorage.getItem('userid')
  }

  public get basicAuthentication() {
    return { userName: '', password: '' }
  }

  public get language() {
    let lang = localStorage.getItem('lang')
    if (lang) return lang
    return false
  }

  login(user: User) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    return this.http.post<any>(`${this.url}/signin`, user, httpOptions)
  }

  forgetPassword(data: any) {
    return this.http.post(`${this.url}/pass-reset`, data)
  }

  logout(data: any) {
    localStorage.removeItem('currentUser')
    localStorage.removeItem('userid')
    return this.http.post(`${this.url}user/logout`, data)
  }
}
