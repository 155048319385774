<div class="content">
  <div class="d-flex justify-content-between mb-2">
    <button class="btn btn-primary" (click)="goBack()"><img src="../../../assets/maunda/back.svg" alt="back" /> Back</button>
  </div>
  <div class="card p-5">
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label" for="description">Description:</label>
          <div class="card" style="height: 500px">
            <div class="card-body" style="overflow-y: auto; scrollbar-width: thin">
              <div *ngIf="upliftDetails?.post; else tempText" [innerHTML]="upliftDetails.post"></div>
              <ng-template #tempText>
                <div>No Description Added by User</div>
              </ng-template>
            </div>
          </div>
        </div>
        <br />
        <ng-container *ngIf="previewUrl && linkPreview">
          <a [href]="previewUrl" class="border d-inline-flex p-3 rounded" style="text-decoration: none; color: black" target="_blank">
            <img [src]="linkPreview.image" alt="" style="max-width: 50px; max-height: 50px; margin: auto" />
            <div>
              <p class="ms-2 m-0" style="font-size: 14px; padding: 10px 10px 0px 10px; line-height: normal">{{ linkPreview.title }}</p>
              <p class="ms-2 m-0" style="font-size: 12px; padding: 0px 10px; margin: 0px; line-height: normal">{{ linkPreview.description }}</p>
            </div>
          </a>
        </ng-container>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label class="form-label" for="upliftId">Created {{ report ? 'by' : 'for' }}:</label>
          <div class="form-control d-flex">
            <img [src]="upliftDetails.user.profilePicUrl" alt="profilePicUrl" style="width: 50px; height: 50px; border-radius: 5px" />
            <div>
              <p class="m-0 ms-2" style="font-size: 14px; font-weight: 500">{{ upliftDetails.user.username }}</p>
              <p class="m-0 ms-2" style="font-size: 11px">{{ upliftDetails.user.firstName }} {{ upliftDetails.user.lastName }}</p>
            </div>
          </div>
        </div>
        <div *ngIf="!report" class="form-group">
          <label class="form-label" for="postSerialNo">Uplift Position:</label>
          <input id="postSerialNo" type="text" class="form-control" value="{{ upliftDetails.serialNo }}" disabled />
        </div>
        <div *ngIf="!report" class="form-group">
          <label class="form-label" for="tag">Tag:</label>
          <input id="tag" type="text" class="form-control" value="{{ upliftDetails.tag }}" disabled />
        </div>
        <div class="form-group">
          <label class="form-label" for="likeCount">Like Count:</label>
          <input id="likeCount" type="text" class="form-control" value="{{ upliftDetails.likeCount }}" disabled />
        </div>
        <div class="form-group">
          <label class="form-label" for="commentCount">Comment Count:</label>
          <input id="commentCount" type="text" class="form-control" value="{{ upliftDetails.commentCount }}" disabled />
        </div>
        <div class="form-group">
          <label class="form-label" for="createdAt">Created Time:</label>
          <input id="createdAt" type="text" class="form-control" value="{{ upliftDetails.createdAt | date : 'dd MMM YYYY, HH:mm a' }}" disabled />
        </div>
      </div>
    </div>
  </div>
</div>
